// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #1470a3;
  --ion-color-primary-rgb: 20,112,163;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #12638f;
  --ion-color-primary-tint: #2c7eac;

  --ion-color-secondary: #86ba90;
  --ion-color-secondary-rgb: 134,186,144;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #76a47f;
  --ion-color-secondary-tint: #92c19b;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112,68,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #f4f3bb;
  --ion-color-warning-rgb: 244,243,187;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #d7d6a5;
  --ion-color-warning-tint: #f5f4c2;

  --ion-color-danger: #df2935;
  --ion-color-danger-rgb: 223,41,53;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #c4242f;
  --ion-color-danger-tint: #e23e49;

  --ion-color-dark: #444444;
  --ion-color-dark-rgb: 68,68,68;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #3c3c3c;
  --ion-color-dark-tint: #575757;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);

  --ion-background-color: #F3F5F9;
}


  strong{
    --ion-font-family:  'Elle Futura Bold'!important;
    font-family:  'Elle Futura Bold' !important;
  }

  *:not(strong)  {
    --ion-font-family:  'Elle Futura'!important;
    font-family:  'Elle Futura' !important;
  }
