/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



.appointment-confirmation-modal, .checkin-confirmation-modal {
    background: rgba(0, 0, 0, 0.5) !important;
    @media screen and (max-width: 728px) {
      .modal-wrapper { border-radius: 20px 20px 0 0; } 
      padding: calc(100vh - 540px) 0% 0% 0%  !important;
    }

    @media screen and (min-width: 728px) {
      .modal-wrapper { border-radius: 20px; } 
      padding: calc(50vh - 280px) calc(50vw - 270px) calc(50vh - 280px) calc(50vw - 270px)  !important;
    }
 }
 
 .pwaPopOver {
  --width: 300px;
}


 
 @font-face {
  font-family: 'Elle Futura';
  font-style: normal;
  font-weight: book;
  src: url('./assets/fonts/elle-futura-book.otf');
}
@font-face {
  font-family: 'Elle Futura Bold';
  font-style: normal;
  font-weight: book;
  src: url('./assets/fonts/elle-futura-bold.otf');
}




.top-bk {
  position: fixed;
  width: 374px;
  img {
    opacity: 0.5;
  }
}
.bottom-bk {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 200px;

  img {
    opacity: 0.5;

  }
 
}

ion-toolbar {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

ion-toolbar ion-menu-button {
  color: var(--ion-color-primary-contrast);
}